import Vue from 'vue'
import Vuex from 'vuex'
import { getRole } from '@/api/common'
import routesAll from '../router'
const { constRoutes, asyncRoutes } = routesAll.options
Vue.use(Vuex)

function hasPermission(roles, route) {
  if (route.meta && route.meta.role) {
    return roles.some(role => route.meta.role.indexOf(role) >= 0)
  } else {
    return true
  }
}
// 递归过略AsyncRoutes路由表
// routes ：待过滤路由表，首次传入的就是asyncRoutes
// roles用户拥有的角色
export function filterAsyncRoutes(routes, roles) {
  const res = []
  routes.forEach(route => {
    // 赋值一份
    const tmp = { ...route }
    // 如果用户有访问的权限就加入结果路由表
    if (hasPermission(roles, tmp)) {
      // 如果存在子路由则递归过滤
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })
  return res
}
export default new Vuex.Store({
  state: {
    openTab: [], // 所有打开的路由
    activeIndex: '/main', // 激活状态
    // -----权限------
    username: '',
    token: localStorage.getItem('token'),
    roles: [], // 权限表['admin','editor']
    routes: [], // 完整路由表
    addRoutes: [] // 用户可以访问的路由表
  },
  mutations: {
    // 添加tabs
    add_tabs(state, data) {
      this.state.openTab.push(data)
    },
    // 删除tabs
    delete_tabs(state, route) {
      let index = 0
      for (const option of state.openTab) {
        if (option.route === route) {
          break
        }
        index++
      }
      this.state.openTab.splice(index, 1)
    },
    // 设置当前激活的tab
    set_active_index(state, index) {
      this.state.activeIndex = index
    },
    // ----权限-----
    setToken: (state, token) => {
      state.token = token
    },
    setUsername: (state, name) => {
      state.username = name
    },
    setRoles: (state, roles) => {
      state.roles = roles
    },
    setRoutes: (state, routes) => {
      state.addRoutes = routes
      constRoutes[0].children = [constRoutes[0].children[0], ...routes]
      // constRoutes[0].children.push(...routes)
      state.routes = constRoutes
    }
  },
  actions: {
    async getUserInfo({ commit, state }) {
      // 模拟获取用户角色信息
      /* eslint-disable */
      return new Promise(async resolve => {
        //console.log('222222222-->')
        const { data: res } = await getRole(
          window.sessionStorage.getItem('token')
        )
        // ['admin']
        const roles = res.data
        commit('setRoles', roles)
        resolve({ roles })
      })
      /* eslint-enable */
    },
    generateRoutes({ commit, state }, roles) {
      // console.log('roles', roles)
      // 过滤路由的方法
      return new Promise(resolve => {
        const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
        commit('setRoutes', accessedRoutes)
        resolve(state.routes)
      })
    },
    resetToken({ commit }) {
      return new Promise(resolve => {
        commit('setToken', '')
        commit('setRoles', [])
        localStorage.removeItem('token')
        resolve()
      })
    }
  }
})
