import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '@/components/home/home.vue'

Vue.use(VueRouter)
// 解决路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}
export const constRoutes = [
  {
    path: '/home',
    name: '概况',
    redirect: '/general',
    component: Home,
    meta: {
      title: '概况',
      keepAlive: true
    },
    children: [
      {
        path: '/general',
        name: '概况',
        component: () => import('@/components/general/general1.vue'),
        meta: {
          title: '概况',
          icon: 'el-icon-monitor'
        }
      },
      {
        path: '/record',
        name: '领取记录',
        component: () => import('@/components/member/coupon/record.vue'),
        meta: {
          title: '领取记录',
          icon: 'el-icon-monitor'
        }
      },
      {
        path: '/question-list',
        name: '问题列表',
        component: () => import('@/components/system/question-list.vue'),
        meta: {
          title: '问题列表',
          icon: 'el-icon-monitor'
        }
      },
      {
        path: '/video-list',
        name: '设备列表',
        component: () => import('@/components/equipment/camera/video-list.vue'),
        meta: {
          title: '设备列表',
          icon: 'el-icon-monitor'
        }
      },
      {
        path: '/productCate',
        name: '所属业态分类',
        component: () => import('@/components/shop/productCate.vue'),
        meta: {
          title: '所属业态分类',
          icon: 'el-icon-monitor'
        }
      },
      {
        path: '/air-list',
        name: '传感器设备列表',
        component: () => import('@/components/equipment/air/air-list.vue'),
        meta: {
          title: '传感器设备列表',
          icon: 'el-icon-monitor'
        }
      },
      {
        path: '/video-265',
        name: '摄像头控制',
        component: () => import('@/components/equipment/camera/video-265.vue'),
        meta: {
          title: '摄像头控制',
          icon: 'el-icon-monitor'
        }
      },
      {
        path: '/register',
        name: '登记记录',
        component: () => import('../components/member/active/register.vue'),
        meta: {
          title: '登记记录',
          icon: 'el-icon-monitor'
        }
      },
      {
        path: '/statistics-detail',
        name: '优惠券统计详情',
        component: () =>
          import('../components/member/coupon/statistics-detail.vue'),
        meta: {
          title: '优惠券统计详情',
          icon: 'el-icon-monitor'
        }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/components/login/newLogin.vue'),
    hidden: true // 导航菜单忽略该选项
  }
]
export const asyncRoutes = [
  {
    path: '/market',
    name: '市场管理',
    meta: {
      title: '市场管理',
      icon: 'el-icon-school',
      role: ['platformAdmin', 'marketAdmin']
    },
    component: () => import('@/components/market/marketManage.vue'),
    children: [
      {
        path: '/marketList',
        name: '市场列表',
        component: () => import('@/components/market/market.vue'),
        meta: {
          title: '市场列表',
          role: ['platformAdmin']
        }
      },
      {
        path: '/boothAdmin',
        name: '摊位管理',
        component: () => import('@/components/market/boothAdmin.vue'),
        meta: {
          title: '摊位管理',
          role: ['platformAdmin', 'marketAdmin']
        }
      },
      {
        path: '/district',
        name: '区域管理',
        component: () => import('@/components/market/district.vue'),
        meta: {
          title: '区域管理',
          role: ['platformAdmin', 'marketAdmin']
        }
      },
      {
        path: '/distribution',
        name: '分销管理',
        component: () => import('@/components/market/distribution.vue'),
        meta: {
          title: '分销管理',
          role: ['platformAdmin', 'marketAdmin']
        }
      }
    ]
  },
  {
    path: '/product',
    name: '商品管理',
    meta: {
      title: '商品管理',
      icon: 'el-icon-goods',
      role: ['platformAdmin', 'marketAdmin']
    },
    component: () => import('@/components/shop/productManage.vue'),
    children: [
      {
        path: '/merchantCategory',
        name: '业态分类管理',
        component: () => import('@/components/shop/merchantCategory.vue'),
        meta: {
          title: '业态分类管理',
          role: ['platformAdmin', 'marketAdmin']
        }
      },
      {
        path: '/goodsClass',
        name: '商品分类',
        component: () => import('@/components/goods/productClass.vue'),
        meta: {
          title: '商品分类',
          role: ['platformAdmin', 'marketAdmin']
        }
      },
      {
        path: '/goodsList',
        name: '标准商品列表',
        component: () => import('@/components/goods/productList.vue'),
        meta: {
          title: '标准商品列表',
          role: ['platformAdmin', 'marketAdmin']
        }
      }
    ]
  },
  {
    path: '/merchantManage',
    name: '商户管理',
    meta: {
      title: '商户管理',
      icon: 'el-icon-house',
      role: ['platformAdmin', 'marketAdmin', 'operation']
    },
    component: () => import('../components/shop/merchantManage.vue'),
    children: [
      {
        path: '/shop',
        name: '商户信息',
        component: () => import('@/components/shop/shop.vue'),
        meta: {
          title: '商户信息',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/shopManage',
        name: '店铺管理',
        component: () => import('@/components/shop/shopManage.vue'),
        meta: {
          title: '店铺管理',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        },
        children: [
          {
            path: '/shopProduct',
            name: '店铺商品管理',
            component: () => import('../components/shop/shopProduct.vue'),
            meta: {
              title: '店铺商品管理',
              role: ['platformAdmin', 'marketAdmin', 'operation']
            }
          },
          {
            path: '/supplier',
            name: '供货商管理',
            component: () => import('@/components/shop/supplier.vue'),
            meta: {
              title: '供货商管理',
              role: ['platformAdmin', 'marketAdmin', 'operation']
            }
          },
          {
            path: '/review',
            name: '评价列表',
            component: () => import('../components/review/review'),
            meta: {
              title: '评价列表',
              role: ['platformAdmin', 'marketAdmin', 'operation']
            }
          }
        ]
      }
    ]
  },
  {
    path: '/orderManage',
    name: '销售订单',
    meta: {
      title: '销售订单',
      icon: 'el-icon-document',
      role: ['platformAdmin', 'marketAdmin']
    },
    component: () => import('@/components/order/orderManage.vue'),
    children: [
      {
        path: '/orderList',
        name: '订单列表',
        component: () => import('@/components/order/orderList.vue'),
        meta: {
          title: '订单列表',
          role: ['platformAdmin', 'marketAdmin']
        }
      },
      {
        path: '/shopSales',
        name: '店铺销售额',
        component: () => import('@/components/order/shopSales.vue'),
        meta: {
          title: '店铺销售额',
          role: ['platformAdmin', 'marketAdmin']
        }
      }
    ]
  },
  {
    path: '/equipManage',
    name: '硬件设备',
    meta: {
      title: '硬件设备',
      icon: 'el-icon-video-camera',
      role: ['platformAdmin', 'marketAdmin', 'operation']
    },
    component: () => import('@/components/equipment/equipManage.vue'),
    children: [
      {
        path: '/cameraManage',
        name: '监控管理',
        component: () =>
          import('@/components/equipment/camera/cameraManage.vue'),
        meta: {
          title: '监控管理',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        },
        children: [
          {
            path: '/videoGroup',
            name: '摄像头管理',
            component: () =>
              import('@/components/equipment/camera/video-group.vue'),
            meta: {
              title: '摄像头管理',
              role: ['platformAdmin', 'marketAdmin', 'operation']
            }
          },
          {
            path: '/video-alarm',
            name: '监控报警记录',
            component: () =>
              import('@/components/equipment/camera/video-alarm.vue'),
            meta: {
              title: '监控报警记录',
              role: ['platformAdmin', 'marketAdmin', 'operation']
            }
          }
        ]
      },
      {
        path: '/foodManage',
        name: '食品安全管理',
        component: () =>
          import('@/components/equipment/quality/foodManage.vue'),
        meta: {
          title: '食品安全管理',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        },
        children: [
          {
            path: '/foodTest',
            name: '食安设备',
            component: () =>
              import('@/components/equipment/quality/food-test.vue'),
            meta: {
              title: '食安设备',
              role: ['platformAdmin', 'marketAdmin', 'operation']
            }
          },
          {
            path: '/quality-standard',
            name: '检测项目',
            component: () =>
              import('@/components/quality/quality-standard.vue'),
            meta: {
              title: '检测项目',
              role: ['platformAdmin', 'marketAdmin', 'operation']
            }
          },
          {
            path: '/qualityTest',
            name: '抽检列表',
            component: () => import('@/components/shop/qualityTest.vue'),
            meta: {
              title: '抽检列表',
              role: ['platformAdmin', 'marketAdmin', 'operation']
            }
          }
        ]
      },
      {
        path: '/airGroup',
        name: '传感器管理',
        component: () => import('@/components/equipment/air/air-group.vue'),
        meta: {
          title: '传感器管理',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      }
    ]
  },
  {
    path: '/vipManage',
    name: '会员管理',
    meta: {
      title: '会员管理',
      icon: 'el-icon-coordinate',
      role: ['platformAdmin', 'marketAdmin', 'operation']
    },
    component: () => import('@/components/member/vipManage.vue'),
    children: [
      {
        path: '/memberList',
        name: '会员列表',
        component: () => import('@/components/member/memberList.vue'),
        meta: {
          title: '会员列表',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/memberExpense',
        name: '会员消费总览',
        component: () => import('@/components/member/memberExpense.vue'),
        meta: {
          title: '会员消费总览',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/coupon',
        name: '优惠券管理',
        component: () => import('@/components/member/coupon/coupon.vue'),
        meta: {
          title: '优惠券管理',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/statistics',
        name: '优惠券统计',
        component: () => import('@/components/member/coupon/statistics.vue'),
        meta: {
          title: '优惠券统计',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/active',
        name: '营销活动',
        component: () => import('../components/member/active/active.vue'),
        meta: {
          title: '营销活动',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/integral',
        name: '积分明细',
        component: () => import('../components/member/integral.vue'),
        meta: {
          title: '积分明细',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/integralRule',
        name: '积分规则',
        component: () => import('../components/member/integralRule.vue'),
        meta: {
          title: '积分规则',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      }
    ]
  },
  {
    path: '/systeManage',
    name: '系统管理',
    meta: {
      title: '系统管理',
      icon: 'el-icon-setting',
      role: ['platformAdmin', 'marketAdmin', 'operation']
    },
    component: () => import('../components/system/systeManage.vue'),
    children: [
      {
        path: '/banner',
        name: '广告设置',
        component: () => import('../components/system/banner/banner.vue'),
        meta: {
          title: '广告设置',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/question',
        name: '常见问题',
        component: () => import('../components/system/question.vue'),
        meta: {
          title: '常见问题',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/feedback',
        name: '用户反馈',
        component: () => import('../components/system/feedback/feedback.vue'),
        meta: {
          title: '用户反馈',
          role: ['platformAdmin', 'marketAdmin', 'operation']
        }
      },
      {
        path: '/admin',
        name: '管理员列表',
        component: () => import('../components/role/admin.vue'),
        meta: {
          title: '管理员列表',
          role: ['platformAdmin', 'marketAdmin', 'marketAdmin']
        }
      },
      {
        path: '/clientList',
        name: '客户端版本管理',
        component: () => import('../components/client/adminList.vue'),
        meta: {
          title: '客户端版本管理',
          role: ['platformAdmin']
        }
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  routes: constRoutes,
  asyncRoutes,
  constRoutes
})

// 路由导航卫士，如果没有token的话，页面跳转至login登录页
const whiteList = ['/login'] // 无需令牌白名单
router.beforeEach(async (to, from, next) => {
  const hasToken = window.sessionStorage.getItem('token')
  if (hasToken) {
    if (to.path === '/login') {
      next('/home')
    } else {
      const hasRoles = store.state.roles && store.state.roles.length > 0
      if (hasRoles) {
        // 说明用户已经获取过角色，放行,也是最终的出口
        next()
      } else {
        // 没有角色要去获取角色
        try {
          // 先获取用户信息
          const { roles } = await store.dispatch('getUserInfo')
          // 根据当前用户角色过滤出可以访问的路由
          const accessRoutes = await store.dispatch('generateRoutes', roles)
          // console.log('111111--->', acc)
          router.addRoutes(accessRoutes)
          // 继续切换路由，确保addRoutes完成
          next({ ...to, replace: true })
          // next('/')
        } catch (error) {
          // 出现需重置令牌并且重新登录
          await store.dispatch('resetToken')
          next('/login')
          // alert(error || '未知错误')
        }
      }
    }
  } else {
    // 未登录
    if (whiteList.indexOf(to.path) !== -1) {
      // 白名单中的路由放过
      next()
    } else {
      // 不是白名单中的就直接重定向至登录页
      next('/login')
    }
  }
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
})

export default router
