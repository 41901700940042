import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
import axios from 'axios'
// 引入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
// 引入视频播放组件
import VueAliplayerV2 from 'vue-aliplayer-v2'

Vue.use(VueAliplayerV2)

Vue.use(VueQuillEditor)

Vue.config.productionTip = false

// 导入axios
axios.defaults.baseURL = '/api/v1/admin/'
axios.interceptors.request.use(config => {
  config.baseURL = config.url.includes('/ws/place/') ? '' : config.baseURL
  // console.log(config)
  config.headers['admin-authorization'] = window.sessionStorage.getItem('token')
  config.headers.bundle = 'market.admin'
  config.headers['Content-Type'] = 'application/json'
  return config
})

Vue.prototype.$http = axios

// 定义全局的时间过滤器
Vue.filter('dataFormat', function(originVal) {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')

  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
// 处理Token失效
/* 响应拦截器 */
axios.interceptors.response.use(
  response => {
    if (response.data.code) {
      switch (response.data.code) {
        case 10005: // 与后台约定登录失效的返回码,根据实际情况处理
          window.sessionStorage.clear() // 删除用户信息
          // 如果超时就处理 ，指定要跳转的页面(比如登陆页)
          router.replace({
            path: '/login'
          })
      }
    }
    return response
  },
  error => {
    return Promise.reject(error.response.data) // 返回接口返回的错误信息
  }
)

const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue
