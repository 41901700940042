import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: '/api/v1/admin/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
axios.interceptors.request.use(config => {
  // console.log('token', window.sessionStorage.getItem('token'))
  // console.log(config)
  // config.headers.admin_authorization = window.sessionStorage.getItem('token')
  config.headers['admin-authorization'] = window.sessionStorage.getItem('token')
  return config
})

export default service
