// 公共api文件
import request from '../utils/request'
// 引入qrcode-decoder
import QrCode from 'qrcode-decoder'
// 获取城市列表
export function getCity(params) {
  return request({
    url: 'region',
    method: 'get',
    params
  })
}
// 获取商品分类的列表
export function getProductClassList(params) {
  return request({
    url: '/productCategory/list',
    method: 'get',
    params
  })
}

// 传入file对象，返回promise
export function getQrUrl(file) {
  // 获取临时路径 chrome有效，其他浏览器的方法请自行查找
  const url = window.webkitURL.createObjectURL(file)
  // 初始化
  const qr = new QrCode()
  // 解析二维码，返回promise
  return qr.decodeFromImage(url)
}
export function getRole(token) {
  return request({
    url: '/user/role',
    method: 'get',
    headers: {
      'admin-authorization': token
    }
  })
}
