<template>
  <!-- 布局容器 -->
  <el-container class="home_container">
    <!-- 头部区域 -->
    <el-header>
      <div
        style="margin-left: 20px;"
        class="header"
      >
        <img
          src="../../assets/cai.png"
          alt=""
        />
        <span style="margin-left: 20px;">智慧农贸运营管理系统</span>
      </div>
      <div>
        <!-- 头像 -->
        <div class="avator">
          <img
            src="../../assets/img/home/avator.png"
            alt=""
          />
          <span>{{ userName }}</span>
        </div>
        <!-- 消息 -->
        <!-- <div class="message">
          <i class="el-icon-message"></i>
          <span>消息</span>
        </div> -->
        <!-- 修改密码 -->
        <div
          class="message"
          @click="editPassword"
        >
          <i class="el-icon-lock"></i>
          <span>修改密码</span>
        </div>
        <!-- 退出登录 -->
        <div
          class="message"
          @click="logout"
        >
          <i class="el-icon-sold-out"></i>
          <span>退出登录</span>
        </div>
      </div>
    </el-header>
    <!-- 非头部区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside width="200px">
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#fff"
          text-color="#606266"
          active-text-color="#3E9DFF"
          :unique-opened="true"
          :default-active="$route.path"
          router
          v-for="(item, index) in routes"
          :key="index"
        >
          <!--一级菜单-->
          <template>
            <el-submenu
              v-if="item.children && item.children.length"
              :index="item.path"
            >
              <template slot="title">
                <i :class="item.meta.icon"></i>
                <span>{{ item.meta.title }}</span>
              </template>

              <!--二级菜单-->
              <template v-for="itemChild in item.children">
                <el-submenu
                  v-if="itemChild.children && itemChild.children.length"
                  :index="itemChild.path"
                  :key="itemChild.path"
                >
                  <template slot="title">
                    <i :class="itemChild.meta.icon"></i>
                    <span>{{ itemChild.meta.title }}</span>
                  </template>
                  <!--三级菜单-->
                  <el-menu-item
                    v-for="itemChild_child in itemChild.children"
                    :index="itemChild_child.path"
                    :key="itemChild_child.path"
                  >
                    <span
                      slot="title"
                      class="three"
                    >{{
                      itemChild_child.meta.title
                    }}</span>
                  </el-menu-item>
                </el-submenu>
                <el-menu-item
                  v-else
                  :index="itemChild.path"
                  :key="itemChild.path"
                >{{ itemChild.meta.title }}</el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item
              v-else
              :index="item.path"
              :key="item.path"
            >
              <i :class="item.meta.icon"></i>
              <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>

      <!-- 内容主体 -->
      <el-main>
        <!-- 此处放置el-tabs代码 -->
        <div>
          <el-tabs
            v-model="activeIndex"
            type="card"
            closable
            v-if="openTab.length"
            @tab-click="tabClick"
            @tab-remove="tabRemove"
          >
            <el-tab-pane
              :key="item.name"
              v-for="item in openTab"
              :label="item.name"
              :name="item.route"
            >
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 第一行，当前农贸市场开始 -->
        <div class="market">
          <span>当前农贸市场：</span>
          <el-select
            v-model="currentMarketId"
            placeholder="请选择"
            @change="changeMarketId"
          >
            <el-option
              v-for="item in marketList"
              :key="item.id"
              :label="item.marketName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 路由占位符 -->
        <router-view v-if="isRouterAlive"></router-view>
      </el-main>
    </el-container>
    <!-- 修改对话框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="editPasswordDialogVisible"
      width="30%"
      @close="clearInfo"
    >
      <el-form
        ref="resetPasswordFormRef"
        :model="changePasswordForm"
        :rules="resetPasswordFormRules"
        label-width="70px"
        class="login_form"
      >
        <!-- 旧密码 -->
        <el-form-item
          prop="originPassword"
          label="旧密码"
        >
          <el-input
            v-model="changePasswordForm.originPassword"
            @input="passwordInput"
            placeholder="请输入旧密码"
          >
          </el-input>
        </el-form-item>
        <!-- 新密码 -->
        <el-form-item
          prop="newPassword"
          label="新密码"
        >
          <el-input
            v-model="changePasswordForm.newPassword"
            @input="passwordInput2"
            placeholder="请输入新密码"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editPasswordDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="resetPassword"
        >确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  computed: {
    ...mapState({
      routes: state => state.routes.filter(item => !item.hidden)[0].children
    }),
    openTab () {
      return this.$store.state.openTab
    },
    activeIndex: {
      get () {
        return this.$store.state.activeIndex
      },
      set (val) {
        this.$store.commit('set_active_index', val)
      }
    }
  },
  data () {
    return {
      isRouterAlive: true,
      // 市场列表
      marketList: [],
      // 市场总数
      total: 0,
      // 当前所在市场的id
      currentMarketId: '',
      // 修改密码
      editPasswordDialogVisible: false,
      // 修改密码入参
      changePasswordForm: {
        newPassword: '',
        originPassword: ''
      },
      // 密码验证规则
      resetPasswordFormRules: {
        // 必填，3-30位通过
        originPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在 5 到 30 个字符', trigger: 'blur' }
        ],
        // 密码必填，5-10位通过
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在 5 到 30 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getMarket()
    // 获取登录名的方法
    this.userName = window.sessionStorage.getItem('userName')
  },
  watch: {
    $route (to, from) {
      // 判断路由是否已经打开
      // 已经打开的 ，将其置为active
      // 未打开的，将其放入队列里
      let flag = false
      for (const item of this.openTab) {
        // console.log('item.name', item.name)
        // console.log('to.name', to.name)

        if (item.name === to.name) {
          // console.log('to.path', to.path)
          this.$store.commit('set_active_index', to.path)
          flag = true
          break
        }
      }

      if (!flag) {
        // console.log('to.path', to.path)
        this.$store.commit('add_tabs', { route: to.path, name: to.name })
        this.$store.commit('set_active_index', to.path)
      }
    }
  },
  // computed: {

  // },
  mounted () {
    // console.log(this.routes, '菜单')
    // 刷新时以当前路由做为tab加入tabs
    // 当前路由不是首页时，添加首页以及另一页到store里，并设置激活状态
    // 当前路由是首页时，添加首页到store，并设置激活状态
    if (this.$route.path !== '/' && this.$route.path !== '/main') {
      // console.log('1')
      // this.$store.commit('add_tabs', { route: '/general', name: '' })
      // this.$store.commit('add_tabs', {
      //   route: this.$route.path,
      //   name: this.$route.name
      // })
      // this.$store.commit('set_active_index', this.$route.path)
    } else {
      // console.log('2')
      this.$store.commit('add_tabs', { route: '/main', name: 'main' })
      this.$store.commit('set_active_index', '/main')
      this.$router.push('/')
    }
  },
  methods: {

    // 退出按钮，做2个操作，1.清除token 2.路由推送到登录页
    logout () {
      window.sessionStorage.clear()
      this.$store.commit('setRoles', [])
      this.$store.commit('setRoutes', [])
      // this.$store.commit(this.routes)
      this.$router.push('/login')
      this.$store.state.openTab = []
      this.$store.state.activeIndex = '/main'
    },
    // tab标签点击时，切换相应的路由
    tabClick (tab) {
      // console.log('tab', tab)
      // console.log('active', this.activeIndex)
      this.$router.push({ path: this.activeIndex })
    },
    // 移除tab标签
    tabRemove (targetName) {
      // console.log('tabRemove', targetName)
      // 首页不删
      if (targetName === '/general') {
        return
      }
      this.$store.commit('delete_tabs', targetName)
      if (this.activeIndex === targetName) {
        // 设置当前激活的路由
        if (this.openTab && this.openTab.length >= 1) {
          console.log(
            '=============',
            this.openTab[this.openTab.length - 1].route
          )
          this.$store.commit(
            'set_active_index',
            this.openTab[this.openTab.length - 1].route
          )
          this.$router.push({ path: this.activeIndex })
        } else {
          // this.$router.push({ path: '/general' })
        }
      }
    },
    // 请求所有菜市场列表
    async getMarket () {
      const { data: res } = await this.$http.get(
        'agriculturalMarket/marketList?limit=300&pageNo=1'
      )
      this.marketList = res.data.data
      // 第一个菜市场作为默认
      this.currentMarketId = this.marketList[0].id
      // 存到session中
      // window.sessionStorage.setItem('currentMarketId', this.currentMarketId)
    },
    // 重新请求
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 修改选择框，找到marketId
    changeMarketId () {
      console.log(this.currentMarketId)
      // 切换的时候，id在session中改变
      window.sessionStorage.setItem('currentMarketId', this.currentMarketId)
      this.reload()
    },
    // 修改密码
    editPassword () {
      this.editPasswordDialogVisible = true
    },
    // 提交修改密码请求
    async resetPassword () {
      // 验证表单是否合理
      this.$refs.resetPasswordFormRef.validate(async valid => {
        if (!valid) return
        // 验证通过，进行下一步操作
        const { data: res } = await this.$http.put(
          'changePassword',
          this.changePasswordForm
        )
        // console.log(response)
        if (res.code !== 0) {
          return this.$message.error(res.message)
        }
        this.$message.success('修改密码成功')
        this.editPasswordDialogVisible = false
      })
    },
    // 关闭对话框
    clearInfo () {
      this.changePasswordForm = {}
    },
    // 密码限制只能数字和字母
    passwordInput (val) {
      // 账号的实时输入
      // console.log(val);
      const codeReg = new RegExp('[A-Za-z0-9]+') // 正则 英文+数字；
      const len = val.length
      let str = ''
      for (var i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i]
        }
      }
      this.changePasswordForm.originPassword = str
    },
    passwordInput2 (val) {
      // 账号的实时输入
      // console.log(val);
      const codeReg = new RegExp('[A-Za-z0-9]+') // 正则 英文+数字；
      const len = val.length
      let str = ''
      for (var i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i]
        }
      }
      this.changePasswordForm.newPassword = str
    }
  }
}
</script>

<style lang="less" scoped>
.home_container {
  height: 100%;
}
.el-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #268cfa;
  font-size: 20px;
}
.el-header div {
  display: flex;
  align-items: center;
}
.avator {
  font-size: 14px;
  color: #303133;
}
.avator img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  color: #909399;
  font-size: 25px;
  span {
    font-size: 10px;
  }
}
.message:hover {
  cursor: pointer;
}
.el-header .header img {
  width: 50px;
  height: 50px;
}
.el-menu {
  border-right: none;
}
.el-main {
  background-color: #f7f7f7;
}
.toggle-button {
  // background-color: #666666;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.el-col {
  margin-right: 15px;
}
.el-tabs {
  margin-top: 0;
}
.el-tabs__nav-wrap {
  background-color: #fff;
}
.market {
  margin-bottom: 20px;
}
// .three {
//   color: #909399;
//   font-size: 14px;
// }
</style>
